.display-items-wrapper {
  width: 100%;
  background-image: url("https://northshoresoapworks.com/images/category_bg.png");
  background-repeat: repeat;
  color: var(--mocha);
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.display-items-category-title {
  margin-top: 10px !important;
  position: relative;
}

.display-item-container {
  max-width: 1980px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: center;
  padding: 20px 2%;
  padding-top: 0;
}

.back-button {
  position: absolute;
  left: 20px;
  font-size: 16pt;
  background-color: var(--meadow);
  padding: 5px 20px;
  color: var(--white);
  text-decoration: none;
  display: flex;
  border-radius: 50px;
  border: 1px solid var(--mocha);
  transition: 200ms;
  display: flex;
  align-items: center;
}

.back-button svg {
  fill: var(--white);
  transition: 200ms;
}

.back-button:hover {
  background-color: var(--cypress);
}

.back-button:hover svg {
  transform: translateX(-10px);
}

@media screen and (max-width: 800px) {
  .back-button {
    display: none;
  }
}

/* This is the styles for the sub category nav bar */

.categories-nav-wrapper {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 700px) {
  .categories-nav-wrapper {
    display: none;
  }
}

.categories-nav-container {
  width: 100%;
  max-width: 1200px;
  padding: 10px 6%;
  background-color: var(--mocha);
  margin-bottom: 40px;
  border: 1px solid var(--walnut);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.categories-nav-container div {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  line-height: 35%;
  font-size: 16pt;
}

.categories-nav-container button {
  background: none;
  border: none;
  color: var(--cream);
  transition: 200ms;
}

.categories-nav-container button:hover {
  color: var(--bone);
  scale: 1.1;
}

.categories-nav-container hr {
  width: 100%;
  border: 1px solid var(--cream) !important;
}

.categories-select-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 10px 0 0;
  display: none;
  margin-bottom: 10px;
}

.categories-select {
  background-color: var(--cream);
  border: 1px solid var(--mocha);
  border-radius: 5px;
  padding: 5px;
  color: var(--mocha);
  margin-top: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 700px) {
  .categories-select-container {
    display: flex;
  }
}

/* These are the styles for the pagination */

.pagination {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .pagination img {
    display: none;
  }
}

.pagination div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.pagination-btn {
  width: 40px;
  min-width: 40px;
  height: 40px;
  color: var(--mocha);
  background-color: var(--cream);
  border: 1px solid var(--mocha);
  border-radius: 10px;
  transition: 200ms;
  font-size: 14pt;
}

.pagination-btn:hover {
  background-color: var(--bone);
  font-size: 18pt;
}

.pagination-active {
  background-color: var(--bone);
}

.selected {
  scale: 1.1;
  color: var(--stone) !important;
}

/* $ Soap Carousel */

.display-items-soap-carousel {
  width: 100%;
  max-width: 100%;
  overflow-y: scroll;
  height: 150px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.soap-carousel-images {
  width: 242px;
  height: 144px;
  border-radius: 20px;
}

.display-items-carousel-btn-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.display-items-carousel-btn {
  fill: var(--mocha);
  background: none;
  border: none;
  transition: 200ms;
}

.display-items-carousel-btn:hover {
  fill: var(--walnut);
}

@media screen and (max-width: 800px) {
  .display-items-carousel-btn-container {
    display: none;
  }
}

.loading-spinner {
  width: 100%;
  background-image: url("https://northshoresoapworks.com/images/category_bg.png");
  background-repeat: repeat;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-items-soap-description {
  max-width: 1200px;
  border: 1px solid var(--mocha);
  padding: 1% 5%;
  font-size: 14pt;
  border-radius: 20px;
  font-family: arial;
  background-color: #f2f1f0;
  margin-bottom: 20px !important;
  text-align: left;
}

.display-items-soap-title {
  margin: 10px 0 !important;
}
