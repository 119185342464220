:root {
  --white: white;
  --cream: #eee7d5;
  --stone: #dabda5;
  --mocha: #a48371;
  --mocha-opacity: #a4837195;
  --meadow: #a0a168;
  --meadow-light: #b6b775;
  --cypress: #717f66;
  --khaki: #625a3c;
  --walnut: #5f4438;
  --bone: #e2d9c4;
  --bone-opacity: #e2d9c485;
  --black: #1a1a1a;
  --black-opacity: #1a1a1a3e;
  --grey: #d6d6d6;
  --red: #842029;
  --small: 12pt;
  --medium: 14pt;
  --large: 24pt;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "Copperplate" !important;
  src: url("../fonts/OPTICopperplate-Heavy.otf") format("opentype");
  /* Additional font styles (e.g., font-weight, font-style) can be specified here */
}

.arial {
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--medium);
}

body,
html {
  background-color: var(--cream) !important;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Copperplate" !important;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

hr {
  border: 1px solid var(--mocha) !important;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 p {
  margin: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-family: "Copperplate" !important;
}

h1 {
  font-size: 2rem !important;
  font-family: "Copperplate" !important;
}

h3 {
  font-size: var(--large);
  font-family: "Copperplate" !important;
}

p {
  margin: 0px !important;
}

.coming-soon {
  width: 100%;
  font-size: var(--large);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--mocha);
}

.image-placeholder {
  background-color: var(--cypress);
  border-radius: 20px;
  animation: pulse 1s infinite;
}

.image-placeholder.fade-out {
  opacity: 0;
}

@keyframes pulse {
  0% {
    background-color: var(--meadow);
  }

  50% {
    background-color: var(--meadow-light);
  }

  100% {
    background-color: var(--meadow);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.image-loading {
  opacity: 0;
}

.fade-in {
  animation: fade-in 1s;
}

/* Logo */

.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bird-1 {
  transform: scaleX(-1);
}

.aboutus-logo {
  aspect-ratio: 1018 / 255;
  width: 100%;
  height: auto;
  max-width: 60%;
  border-radius: 10px;
}

.bird-img {
  width: 150px;
  height: auto;
}

@media screen and (max-width: 800px) {
  .bird-img {
    display: none;
  }

  .logo-container {
    justify-content: center;
  }
}

.main-loading {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-opacity);
  z-index: 100;
  top: 0;
  left: 0;
  cursor: not-allowed;
}

.initial-modal .modal-dialog {
  max-width: 500px;
}

.initial-modal .modal-content {
  background-color: var(--cream);
  color: var(--mocha);
}

/* @media screen and (max-width: 665px) { */
  /* .initial-modal img { */
    /* display: none; */
  /* } */
/* } */

.selection-container {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.carousel-item {
  position: relative;
}

.item-modal-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
}

.option-variation-btn button {
  background-color: var(--mocha);
  color: var(--cream);
  border: none;
}
