nav {
    background-color: var(--mocha);
    height: 70px;
}

nav ul {
    margin: 0;
    list-style: none;
    display: flex;
    gap: 10px;
}

.nav-link {
    color: var(--stone) !important;
}

.active {
    color: var(--cream) !important;

}

.nav-link:hover {
    color: var(--cream) !important;
}

#navbarNav {
    background-color: var(--mocha);
    padding: 10px;
    z-index: 1;
}

.cart-button {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 10px;
}

.cart-button svg {
    fill: var(--stone);
    transition: 300ms;
}

.cart-button svg:hover {
    fill: var(--cream);
}

.navbar {
    position: relative;
}

@media screen and (max-width: 992px) {
    .navbar-nav {
        position: relative;
        z-index: 2;
        pointer-events: auto;
    }
}

.navbar .container {
    margin-left: 0px !important;
}

.nav-link a {
    text-decoration: none;
    color: var(--stone);
    transition: 200ms;
}

.nav-link a:hover {
    color: var(--cream);
}

.navbar-nav {
    background-color: var(--mocha);
}

@media screen and (max-width: 992px) {
    .navbar-nav {
        padding: 20px !important;
        border-radius: 20px;
    }

}

.search-icon svg {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 70px;
    top: 12px;
    color: var(--stone) !important;
    transition: 200ms;
}

.search-icon svg:hover {
    color: var(--cream) !important;
}

#basic-nav-dropdown {
    display: flex;
    align-items: center;
}

.nav-dropdown-categories .dropdown-menu {
    background-color: var(--mocha);
    border: 1px solid var(--walnut);
}

.nav-dropdown-link {
    color: var(--stone);
    text-decoration: none;
}

.nav-dropdown-link:hover {
    color: var(--cream);
}

.nav-dropdown-link-container {
    background-color: var(--mocha) !important;
}

.nav-dropdown-link-container:hover {
    background-color: var(--mocha) !important;
}

.logo-link {
    width: 50px !important;
    height: 100%;
}

.logo-link-img {
    width: 50px !important;
    height: auto;
}

.nav-container {
    justify-content: start !important;
}

#basic-navbar-nav-btn {
    margin-left: 20px;
}

.nav-dropdown-link-container {
    color: var(--stone) !important;
}