.thankyou {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--mocha);
    flex-direction: column;
}

.thankyou span {
    font-size: 60px;
    text-align: center;
}

.copy-icon {
    fill: var(--mocha);
}